<template>
	<v-container fluid style="max-width: 1200px">
		<v-fade-transition hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="400" height="120" />
			</template>
			<template v-else>
				<v-row key="transitionEnded">
					<v-col cols="12" md="2" sm="3" xs="5">
						<UserPicture :loading-parent="loading" />
					</v-col>
					<v-col cols="12" md="10" sm="9" xs="7">
						<UserInfo :loading-parent="loading" />
					</v-col>
				</v-row>
			</template>
		</v-fade-transition>

		<v-row>
			<v-col cols="12" lg="5" sm="6" xs="12" class="d-flex flex-column">
				<UserStudies :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
				<UserDescription :loading-parent="loading" />
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" lg="5" sm="6" xs="12" class="d-flex flex-column">
				<UserInterests :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
				<CVSkills :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="5" sm="6" xs="12" class="d-flex flex-column">
				<CVLanguages :loading-parent="loading" />
			</v-col>
			<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
				<CVProjects :loading-parent="loading" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('settings.profile'),
		}
	},
	data() {
		return {
			loading: false,
		}
	},
	components: {
		UserPicture: () => import('@/components/users/UserPicture.vue'),
		UserInfo: () => import('@/components/users/UserInfo.vue'),
		UserStudies: () => import('@/components/users/UserStudies.vue'),
		UserDescription: () => import('@/components/users/UserDescription.vue'),
		UserInterests: () => import('@/components/users/UserInterests.vue'),
		CVSkills: () => import('@/components/cv/CVSkills.vue'),
		CVLanguages: () => import('@/components/cv/CVLanguages.vue'),
		CVProjects: () => import('@/components/cv/CVProjects.vue'),
	},
	created() {
		this.loading = true
		this.fetchCurrentUser().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('user', ['fetchCurrentUser']),
	},
}
</script>
